import React, { useEffect, useState } from 'react'
import commerce from '@lib/api/commerce'
import { FAQ, Layout, SEO } from '@components/common'

import { useUI } from '@components/ui/context'

import { getGlobalContentContext } from '@drf/getGlobalContentContext'
import { getPageContext } from '@drf/getPageContext'
import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next'
import type {
  Homepage,
  Homepage as HomepageType,
} from '@generated/model/homepage'
import { useWindowSize } from 'usehooks-ts'
import { useGlobalContentContext } from '@context/GlobalContentContext'
import { gsap } from 'gsap/dist/gsap'
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin'
import { FittersBlock, Hero, How, Table } from '@components/loretwopage'
import { LoreTwoPage } from '@generated/model/loreTwoPage'
import Performance from '@components/loretwopage/Performance'
import { useRouter } from 'next/router'
import { Container } from '@components/ui'
import { Fitter } from '@generated/model/fitter'
import { LoreTwoPageFaqblock } from '@generated/model/loreTwoPageFaqblock'
import { useJsApiLoader } from '@react-google-maps/api'

if (typeof window !== 'undefined') {
  // Due to SSR we can only register when we are in the browser
  gsap.registerPlugin(ScrollToPlugin)
}

export async function getStaticProps({
  preview,
  locale,
  locales,
}: GetStaticPropsContext) {
  const config = { locale, locales }
  const productsPromise = commerce.getAllProducts({
    variables: { first: 50 },
    config,
    preview,
    // Saleor provider only
    ...({ featured: true } as any),
  })
  const pagesPromise = commerce.getAllPages({ config, preview })
  const siteInfoPromise = commerce.getSiteInfo({ config, preview })
  const { products } = await productsPromise

  const productPromise = commerce.getProduct({
    variables: { slug: 'loretwo' },
    config,
    preview,
  })
  const { product } = await productPromise

  const { pages } = await pagesPromise
  const { categories, brands } = await siteInfoPromise

  const global = await getGlobalContentContext()
  const page: LoreTwoPage = await getPageContext('loretwopage')

  const performance = page.performance_sections

  const fittersBlock = await getPageContext('findfittersblock')
  const fittersMap = await getPageContext('fitters')
  const fitters = Object.keys(fittersMap).map((i) => fittersMap[i])

  return {
    props: {
      products,
      product,
      categories,
      brands,
      pages,
      global,
      page,
      fittersBlock,
      fitters,
      performance,
    },
    revalidate: 60,
  }
}

export default function Home({
  products,
  product,
  global,
  page,
  fittersBlock,
  fitters,
  performance,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const { setData } = useGlobalContentContext()
  const router = useRouter()

  useEffect(() => {
    setData(global)
  }, [global, setData])

  const { width, height } = useWindowSize()

  const [currentFitter, setCurrentFitter] = useState<Fitter | null>(null)

  const {
    setSnappingOn,
    setSnappingOff,
    isSnapping,
    openNav,
    closeNav,
    displayNav,
    setPageTheme,
    showFooter,
    hideFooter,
    setContainerFixedWidth,
  } = useUI()

  useEffect(() => {
    setContainerFixedWidth()
    setPageTheme('LIGHT')
  }, [])

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string,
    libraries: ['places'],
  })

  return (
    <>
      <div>
        {page.hero && <Hero page={page} />}

        {page.table && <Table page={page} product={product} />}

        {page.how_section && <How data={page.how_section} />}

        {performance && (
          <Performance data={{ sections: performance, navigation_title: '' }} />
        )}

        {fittersBlock && (
          <FittersBlock
            data={fittersBlock}
            fitters={fitters}
            currentFitter={currentFitter}
            setCurrentFitter={setCurrentFitter}
            isLoaded={isLoaded}
          />
        )}

        {page.faqblock && product && (
          <Container>
            <FAQ
              title={page.faqblock.title}
              faqs={page.faqblock.faqs}
              expandAll={false}
            />
          </Container>
        )}
      </div>

      <SEO
        title={page.meta_title}
        description={page.meta_description}
        openGraph={{
          type: 'website',
          title: page.meta_title,
          description: page.meta_description,
          images: [
            {
              url: page.meta_image as string,
              width: '800',
              height: '600',
              alt: page.meta_title,
            },
          ],
        }}
      />
    </>
  )
}

Home.Layout = Layout
